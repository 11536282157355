<template>
  <div class="not-found">
    <!-- <div v-if="textKey === 'PortalOrganization'">
      <img :src="'/img/Rfider-fs1-app.png'" alt="Assure+ Go App" class="app-img" />
    </div>
    <object v-else type="image/svg+xml" :data="mySvg" alt="Assure+ Logo" class="not-found-img" :class="sizeClassName"></object> -->

    <div class="not-found-text" :class="{ 'color-inherit': !!colorInherit }" v-html="evaluatedText"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mySvg: require('../../public/img/Rfider-logo-animated.svg'),
    };
  },
  props: {
    text: {
      type: String,
    },
    textKey: {
      type: String,
    },
    size: {
      type: String,
      default: 'small',
    },
    colorInherit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sizeClassName() {
      if (this.size === 'l') {
        return 'not-found-lg';
      }
      return 'not-found-sm';
    },
    evaluatedText() {
      let message = null;

      switch (this.textKey) {
        case 'Things':
          message = 'No Thing found or match the current search.';
          break;
        case 'Items':
          message = 'No Item found or match the current search and filters.';
          break;
        case 'Interactions':
          message = 'No Interaction found or match the current search and filters.';
          break;
        case 'Rfider':
          message = 'Coming Soon';
          break;
        case 'Tasks':
          message = 'No Task found. Please add task(s) for your product.';
          break;
        case 'Services':
          message = 'No Service found. Please add service(s) for your product.';
          break;
        case 'PagePermission':
          message = "You don't have permission to see this page.";
          break;
        case 'NoOrganizationExist':
          message = 'Organization not found.';
          break;
        case 'invitation':
          message = this.text;
          break;
        case 'FSOrganization':
          message = 'Unfortunately you do not have access to this Organization.';
          break;
        case 'PortalOrganization':
          message = 'Unfortunately you do not have portal access to this Organization.';
          break;
        default:
          message = `
            <p>
              To get started please download the Assure+ Go App from the <a class="link" href="https://play.google.com/store/apps/details?id=org.rfider.fs.app" target="_blank"><strong>Google Play</strong></a> or <a class="link" href="https://apps.apple.com/us/app/rfider-fs1/id1531819757" target="_blank"><strong>iTunes Store</strong></a>.<br />
              The Assure+ Go App is a free, easy to use and yet powerful tool designed to help make your job just that little bit easier.
            </p>
            <p>Once you’ve installed the app and logged-in you’ll have access to the tasks your organization has made available for your use.</p>
            <p>Learn more about your <a class="link" href="https://tradewindow.io/legals/privacy-policy" target="_blank">privacy</a> and <a class="link" href="https://www.rfider.com/terms-of-use" target="_blank">terms of use</a>.</p>
            <br />
            <p><strong>Download the app today to get started. It’s Free.</strong></p>
            <br />
            <div class="download-buttons">
              <a href="https://play.google.com/store/apps/details?id=org.rfider.fs.app" target="_blank">GOOGLE PLAY</a>
              <a href="https://apps.apple.com/us/app/rfider-fs1/id1531819757" target="_blank">ITUNES STORE</a>
            </div>
          `;
      }

      if (!message) {
        message = this.text;
      }

      return message;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/pages/_not-found.scss';
</style>
