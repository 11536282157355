// Pages
import NotFound from '@/pages/NotFound.vue';
import AuthCallback from '@/pages/AuthCallback.vue';
import Home from '@/pages/Home/IndexHome.vue';
import Logout from '@/pages/LogoutComponent.vue';
import Signup from '@/pages/SignupComponent.vue';
import FSInteraction from '@/pages/FSApp/InteractionComponent.vue';
import FSApp from '@/pages/FSApp/IndexFSApp.vue';
import SignupConfirmation from '@/pages/SignupConfirmation.vue';
import Task from '@/pages/TaskExecution.vue';
import RoutesStyleguide from './routes.styleguide';
import { Permissions } from '@/utils/Guards';

const AuthLayout = () => import(/* webpackChunkName: "base" */ '@/pages/AuthLayoutComponent.vue');
const Profile = () => import(/* webpackChunkName: "profile" */ '@/pages/Profile/IndexProfile.vue');
const Invitations = () => import(/* webpackChunkName: "profile-invitations" */ '@/pages/Profile/InvitationsComponent.vue');
const Organization = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/IndexOrganization.vue');
const OrganizationDashboard = () => import(/* webpackChunkName: "org-dashboard" */ '@/pages/Organization/DashboardComponent.vue');
const OrganizationSettings = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/SettingsComponent.vue');
const OrganizationAdvancedSettings = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/advancedSettings/indexAdvancedSettings.vue');
const OrganizationAdvancedSettingsWebhooks = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/advancedSettings/webhooksComponent.vue');
const OrganizationAdvancedSettingsAccessTokens = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/advancedSettings/accessTokens.vue');
const OrganizationTasksSettings = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/tasksSettings/indexTaskSettings.vue');
const OrganizationTasksSettingsList = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/tasksSettings/tasksComponent.vue');
const OrganizationJobsList = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/tasksSettings/jobsComponent.vue');
const OrganizationAccessSettings = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/accessSettings/indexAccessSettings.vue');
const OrganizationAccessSettingsTeams = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/accessSettings/teams/TeamsComponent.vue');
const OrganizationAccessSettingsUsers = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/accessSettings/users/UsersComponent.vue');
const OrganizationValueChainsSettings = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/valueChainsSettings/indexValueChain.vue');
const OrganizationValueChainsSettingsList = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/valueChainsSettings/valueChains.vue');
const OrganizationWideSettings = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/wideSettings/indexWideSettings.vue');
const OrganizationInteractions = () => import(/* webpackChunkName: "org-interactions" */ '@/pages/Organization/interactions/interactionsComponent.vue');
const OrganizationThings = () => import(/* webpackChunkName: "org-thing" */ '@/pages/Organization/Things/indexComponent.vue');
const ThingList = () => import(/* webpackChunkName: "org-thing" */ '@/pages/Organization/Things/thingList.vue');
const ThingEdit = () => import(/* webpackChunkName: "org-thing" */ '@/pages/Organization/Things/thingEdit.vue');
const ThingOverview = () => import(/* webpackChunkName: "org-thing" */ '@/pages/Organization/Things/Edit/thingOverview.vue');
const ThingAbout = () => import(/* webpackChunkName: "org-thing" */ '@/pages/Organization/Things/Edit/thingAbout.vue');
const ThingDiscovery = () => import(/* webpackChunkName: "org-thing" */ '@/pages/Organization/Things/Edit/thingDiscovery.vue');
const ThingCollaboration = () => import(/* webpackChunkName: "org-thing" */ '@/pages/Organization/Things/Edit/thingCollaboration.vue');
const ThingSafety = () => import(/* webpackChunkName: "org-thing" */ '@/pages/Organization/Things/Edit/thingSafety.vue');
const ThingPublic = () => import(/* webpackChunkName: "org-thing" */ '@/pages/Organization/Things/Edit/thingPublic.vue');
const ThingTasks = () => import(/* webpackChunkName: "org-thing" */ '@/pages/Organization/Things/Edit/thingTasks.vue');
const Products = () => import(/* webpackChunkName: "org-thing" */ '@/pages/Organization/products/indexProducts.vue');
const ProductsList = () => import(/* webpackChunkName: "org-thing" */ '@/pages/Organization/products/productsComponent.vue');
const OrganizationGeneral = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/wideSettings/generalComponent.vue');
const OrganizationCategories = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/wideSettings/categoriesComponent.vue');
const OrganizationSupportProfile = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/wideSettings/supportProfile.vue');
const ThingSupportProfile = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/Things/Edit/thingSupportProfiles.vue');

const OrganizationTemplates = () => import(/* webpackChunkName: "org" */ '@/pages/Organization/templates/indexTemplates.vue');
const OrganizationTemplatesList = () => import(/* webpackChunkName: "org-dashboard" */ '@/pages/Organization/templates/templatesComponent.vue');
const OrganizationContentList = () => import(/* webpackChunkName: "org-dashboard" */ '@/pages/Organization/templates/contentBlocks.vue');

const GenericFormTask = () => import(/* webpackChunkName: "org-dashboard" */ '@/pages/Organization/tasks/genericFormTask.vue');

const authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/profile',
      component: Profile,
      children: [
        {
          path: 'invitations',
          name: 'Invitations',
          component: Invitations,
          meta: {
            permissions: [Permissions.accounts_read],
          },
        },
      ],
    },
    {
      path: '/org/:id',
      component: Organization,
      meta: {
        permissions: [Permissions.portal_access],
      },
      children: [
        {
          path: 'dashboard',
          name: 'Organization',
          // beforeEnter: (to: any, from: any, next: any) => {
          //   window.location.href = 'https://www.rfider.com/maintenance';
          // },
          component: OrganizationDashboard,
        },
        {
          path: 'things',
          component: OrganizationThings,
          children: [
            // {
            //   path: '/',
            //   name: 'OrganizationThingList',
            //   component: ThingList,
            // },
            {
              path: ':thingId/',
              component: ThingEdit,
              meta: {
                permissions: [Permissions.things_read],
              },
              children: [
                // {
                //   path: '/',
                //   name: 'OrganizationThingEditOverview',
                //   component: ThingOverview,
                // },
                {
                  path: 'about',
                  name: 'OrganizationThingEditAbout',
                  component: ThingAbout,
                },
                {
                  path: 'discovery',
                  name: 'OrganizationThingEditDiscovery',
                  component: ThingDiscovery,
                },
                // {
                //   path: 'collaboration',
                //   name: 'OrganizationThingEditCollaboration',
                //   component: ThingCollaboration,
                // },
                // {
                //   path: 'safety',
                //   name: 'OrganizationThingEditSafety',
                //   component: ThingSafety,
                // },
                {
                  path: 'public',
                  name: 'OrganizationThingEditPublic',
                  component: ThingPublic,
                },
                {
                  path: 'tasks',
                  name: 'OrganizationThingEditTasks',
                  component: ThingTasks,
                },
                {
                  path: 'support',
                  name: 'OrganizationThingSupportProfile',
                  component: ThingSupportProfile,
                },
              ],
            },
          ],
        },
        {
          path: 'interactions',
          name: 'OrganizationInteractions',
          component: OrganizationInteractions,
          meta: {
            permissions: [Permissions.interactions_list],
          },
        },
        {
          path: 'products',
          component: Products,
          children: [
            {
              path: 'list',
              name: 'productsList',
              component: ProductsList,
            },
          ],
        },
        {
          path: '',
          component: OrganizationTemplates,
          children: [
            {
              path: 'content',
              name: 'OrganizationContentList',
              component: OrganizationContentList,
              // meta: {
              //   permissions: [Permissions.categories_list],
              // },
            },
            {
              path: 'templates',
              name: 'OrganizationTemplatesList',
              component: OrganizationTemplatesList,
              // meta: {
              //   permissions: [Permissions.categories_list],
              // },
            },
          ],
        },
        {
          path: 'settings',
          name: 'OrganizationSettings',
          component: OrganizationSettings,
          children: [
            {
              path: 'access',
              component: OrganizationAccessSettings,
              children: [
                {
                  path: 'teams',
                  name: 'OrganizationAccessSettings',
                  component: OrganizationAccessSettingsTeams,
                  meta: {
                    permissions: [Permissions.teams_list],
                  },
                },
                {
                  path: 'users',
                  name: 'OrganizationAccessSettingsUsers',
                  component: OrganizationAccessSettingsUsers,
                  meta: {
                    permissions: [Permissions.organizations_accounts_list],
                  },
                },
              ],
            },
            {
              path: 'advanced',
              component: OrganizationAdvancedSettings,
              children: [
                {
                  path: 'integration-actions',
                  name: 'OrganizationAdvancedSettingsWebhooks',
                  component: OrganizationAdvancedSettingsWebhooks,
                  meta: {
                    permissions: [Permissions.webhooks_list],
                  },
                },
                {
                  path: 'access-tokens',
                  name: 'OrganizationAdvancedSettingsAccessTokens',
                  component: OrganizationAdvancedSettingsAccessTokens,
                  // meta: {
                  //   permissions: [Permissions.webhooks_list],
                  // },
                },
              ],
            },
            {
              path: '',
              component: OrganizationTasksSettings,
              children: [
                {
                  path: 'tasks',
                  name: 'OrganizationTasksSettingsList',
                  component: OrganizationTasksSettingsList,
                  meta: {
                    permissions: [Permissions.tasks_list],
                  },
                },
                {
                  path: 'jobs',
                  name: 'OrganizationJobsList',
                  component: OrganizationJobsList,
                  // meta: {
                  //   permissions: [Permissions.tasks_list],
                  // },
                },
              ],
            },
            {
              path: 'value-chains',
              component: OrganizationValueChainsSettings,
              children: [
                {
                  path: 'list',
                  name: 'OrganizationValueChainsSettingsList',
                  component: OrganizationValueChainsSettingsList,
                  meta: {
                    permissions: [Permissions.components_list],
                  },
                },
              ],
            },
            {
              path: 'organization-settings',
              component: OrganizationWideSettings,
              children: [
                {
                  path: '',
                  name: 'OrganizationGeneral',
                  component: OrganizationGeneral,
                },
                {
                  path: 'categories',
                  component: OrganizationCategories,
                  name: 'OrganizationCategories',
                },
                {
                  path: 'support',
                  component: OrganizationSupportProfile,
                  name: 'OrganizationSupportProfile',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const routes = [
  {
    path: '/loggedin/:path?',
    name: 'AuthCallback',
    component: AuthCallback,
    meta: {
      noAuth: true,
    },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: {
      noAuth: true,
    },
    children: [
      {
        path: 'confirmation',
        name: 'SignupConfirmation',
        component: SignupConfirmation,
      },
    ],
  },
  {
    path: '/fs/',
    name: 'FSApp',
    component: FSApp,
    children: [
      {
        path: 'interaction/:orgId/:interactionId',
        name: 'FSInteraction',
        component: FSInteraction,
      },
    ],
  },
  {
    path: '/loggedout',
    name: 'Logout',
    component: Logout,
    meta: {
      noAuth: true,
    },
  },
  {
    path: '/task/:token?',
    name: 'Task',
    component: Task,
    props: true,
    meta: {
      noAuth: true,
    },
  },
  {
    path: '/org/:id/gft',
    name: 'OrganizationPerformGFT',
    component: GenericFormTask,
  },
  authPages,
  ...RoutesStyleguide,

  {
    path: '*',
    component: NotFound,
    meta: {
      noAuth: true,
    },
  },
];

export default routes;
