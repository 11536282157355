import axios from 'axios';
import settings from '@/settings';
import jwtMiddleware from './middleware/jwt';
import mockMiddleware from './middleware/mockFunctions';

const api = axios.create({
  baseURL: settings.apiUrlRest,
  timeout: 15000,
  headers: { 'Content-Type': 'application/json' },
});

api.interceptors.request.use(
  config => jwtMiddleware(config),
  error => Promise.reject(error),
);

api.interceptors.request.use(
  config => mockMiddleware(config),
  error => Promise.reject(error),
);

api.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(error);
  },
);

const http = api;
export { http };
